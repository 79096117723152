const USER_LEAD_STATUSES = {
	invite_pending: 'invite_pending',
	partial_application: 'partial_application',
	needs_documents: 'needs_documents',
	needs_onboarding_call: 'needs_onboarding_call',
	lapsed: 'lapsed',
	junk: 'junk',
	unqualified: 'unqualified',
	duplicate: 'duplicate',
	test: 'test',
	lapsed_partial: 'lapsed_partial',
	user: 'user',
	preview_mode: 'preview_mode',
};

export default USER_LEAD_STATUSES;
