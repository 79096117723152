/* eslint-disable camelcase */
import STATUS_CODES from 'constants/statusCodes';
import logger from 'helpers/api/logger';
import { getErrorMessage } from 'helpers/error';
import { notifyError } from 'helpers/errorNotifier';
import { verifyJWT } from 'helpers/jwt/verify';
import { withCookiesRestClient } from 'helpers/restclient';
import { getClientHeaders } from 'helpers/restclient/headers';
import nextCookie from 'next-cookies';
import buildUser from './buildUser';

export const allowUtmAndAfcParams = (key) => key.startsWith('utm_') || key.startsWith('afc');

export default async function getUserFromRailsCookie(ctx) {
	if (!nextCookie(ctx)._session_id && !nextCookie(ctx)._multi_session_id) return false;
	const { headers } = createHeaders(ctx);
	let res;
	try {
		const paramsString = getParamsString(ctx?.req?.query, allowUtmAndAfcParams);
		res = await withCookiesRestClient.post(`/users/cookie?${paramsString}`, {}, headers);
		return await getUserData(res, ctx);
	} catch (responseError) {
		const { resolvedUrl } = ctx;
		if (responseError?.status !== STATUS_CODES.UNAUTHORIZED) {
			logger.error(`${getErrorMessage(responseError, 'Get user endpoint error')} - From:${resolvedUrl}`);
		}
		return false;
	}
}

const getUserData = async (res, ctx) => {
	try {
		const { jwt, ...args } = res.data;
		await verifyJWT(jwt);
		return await buildUser(jwt, args, ctx);
	} catch (error) {
		notifyError({ ...error });
		return false;
	}
};

export const createHeaders = (ctx) => {
	const cookies = nextCookie(ctx);
	const session_id = cookies._session_id;
	const master_id = cookies._master_id;
	const refreshToken = cookies.refresh_token;
	const clientHeaders = getClientHeaders(ctx);
	const headers = {
		Accept: 'application/json, application/vnd.backlotcars.v3',
		...clientHeaders,
	};
	const multi_session_id = cookies._multi_session_id;
	const cookieHeaders = [];

	if (session_id) cookieHeaders.push(`_session_id=${session_id}`);
	if (multi_session_id) cookieHeaders.push(`_multi_session_id=${multi_session_id}`);
	if (master_id) cookieHeaders.push(`_master_id=${master_id}`);
	if (refreshToken) cookieHeaders.push(`refresh_token=${refreshToken}`);

	headers['Cookie'] = cookieHeaders.join(';');

	return { headers, session_id, multi_session_id, master_id };
};

export const getParamsString = (params, filter) => {
	if (!params) return '';
	try {
		return Object.entries(params)
			.filter(([key]) => (filter ? filter(key) : true))
			.map((pair) => pair.map(encodeURIComponent).join('='))
			.join('&');
	} catch (error) {
		notifyError(`Error trying to execute getParamsString: ${error}`, {
			metadata: {
				query: params,
			},
		});
		return '';
	}
};
