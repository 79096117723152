// Recommendations
const events = {
	similarVehiclesClicked: 'Similar vehicles clicked',
	vdpRecommendationLoaded: 'VDP recommendation loaded',
	vdpRecommendationClicked: 'VDP recommendation clicked',
	vdpRecommendationSeeMore: 'VDP recommendation see more clicked',

	// Bid Modal
	bidModalClosedWithoutBidding: 'Bid Modal Closed Without Bidding',
	bidModalOpen: 'Buyer - Offer Modal Open',
};

export default events;
