import { useEffect } from 'react';
import { segmentTrack } from 'helpers/integrations/SegmentSnippet';

const SMS_UTM_MEDIUM = 'sms';
const GO_UTM_CAMPAIGN = 'guaranteedofferdetails_seller';

const useTrackGoFromSms = (title, data) => {
	const { utm_medium: utmMedium, utm_campaign: utmCampaign } = data;

	useEffect(() => {
		let checkAnalytics;
		if (utmMedium === SMS_UTM_MEDIUM && utmCampaign === GO_UTM_CAMPAIGN) {
			if (!window.analytics) {
				checkAnalytics = setInterval(() => {
					if (window.analytics) {
						segmentTrack(title, data);
						clearInterval(checkAnalytics);
					}
				}, 100);
			} else {
				segmentTrack(title, data);
			}
		}

		return () => {
			if (checkAnalytics) clearInterval(checkAnalytics);
		};
	}, [utmMedium, utmCampaign, title, data]);
};

export default useTrackGoFromSms;
