import USER_LEAD_STATUSES from 'constants/userLeadStatuses';
import { notifyError } from '../../errorNotifier';
import BuyerEvents from './eventsDictionary/buyers';

export const segmentTrack = (action, params, userContext) => {
	try {
		const newParams = { ...params };

		// Check if the user is in preview mode and update the lead_status accordingly
		if (userContext?.lead_status === USER_LEAD_STATUSES.preview_mode) {
			newParams.lead_status = userContext.lead_status;
		}

		if (window.analytics) {
			window.analytics.track(action, newParams);
		}
	} catch (error) {
		notifyError(error);
	}
};

export const parsePageData = ({ asPath, name, subCategory }) => {
	const path = asPath.split('?')[0];
	const segmentName = `${subCategory}|${name}`;
	return { path, segmentName };
};

export const segmentPageView = (name, properties) => {
	const category = 'backlotcarscom';
	const title = `${category}|${name}`;
	try {
		if (window.analytics) {
			window.analytics.page(category, title, { ...properties, title });
		}
	} catch (error) {
		notifyError(error);
	}
};

export const events = {
	...BuyerEvents,
};
